.home-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg2);
  color: var(--text);
  overflow: hidden;
  padding: 0px 160px;
}

.section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
}

.text {
  flex: 1;
  height: 50vh;
  text-align: left;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
}

.image {
  width: 65vh;
  height: 60vh;
  text-align: center;
  overflow-x: scroll;
  display: flex;
  border-radius: 15px !important;
}

.image:nth-child(1) {
  margin-right: 40px;
}

.image:nth-child(2) {
  margin-left: 40px;
}

.image:nth-child(3) {
  margin-right: 40px;
}

.image img {
  max-width: 100%;
  animation: carousel 12s infinite;
}

@keyframes carousel {
  0% {
    transform: translateX(0%);
  }

  20% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  100% {
    transform: translateX(0%);
  }
}

.image img:hover {
  transform: translateZ(50px);
}

.content {
  padding-top: 200px;
}

.main-content {
  font-size: 30px;
  line-height: 42px;
  font-weight: 500;
  text-align: start;
}

.main-heading {
  font-size: 150px;
  font-weight: 800;
  margin-bottom: 100px;
}

.social-handle-icons {
  display: flex;
  margin-top: 30px;
}

.social-handle-icons a {
  display: flex;
  align-items: center;
  background-color: #fef9f8;
  margin-right: 15px;
  padding: 8px;
  border-radius: 50px;
  text-decoration: none;
  box-shadow: 5px 5px 5px 5px var(--bg2);
  box-shadow: 4px 4px 5px 5px var(--shadow1);
}

.social-handle-icons a:hover {
  scale: 1.04;
}

.social-handle-icons a img {
  width: 40px;
}

.social-handle-icons a p {
  color: black;
  font-size: 24px;
  font-weight: 600;
  padding: 0 10px 5px 12px;
}

@media (max-width: 767px) {
  .home-component {
    padding: 15px 1px;
    align-items: center;
  }

  .section {
    flex-wrap: wrap;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
  }

  /* odd child */
  .section:nth-child(odd) {
    flex-direction: column-reverse;
  }

  .text {
    padding: 25px 0px 0px;
    text-align: center;
    line-height: 22px;
  }

  .image {
    padding: 0px;
    align-items: center;
    max-width: 87vw;
    margin-top: 0%;
  }

  .image:nth-child(1) {
    margin-right: 0px;
  }

  .image:nth-child(2) {
    margin-left: 0px;
    margin-top: 15px;
  }

  .image:nth-child(3) {
    margin-right: 0px;
  }

  .content {
    padding-top: 20px;
  }

  .main-content {
    font-size: 25px;
    line-height: auto;
  }

  .main-heading {
    font-size: 105px;
    margin-bottom: 60px;
  }

  .image img {
    animation: none;
    border-radius: 15px;
  }
}
