.who-are-we-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: var(--bg1);
}

.who-are-we {
  display: flex;
  align-items: center;
  padding: 100px 0px;
}

.who-are-we-image {
  margin-right: 40px;
}

.who-are-we-image img {
  width: 600px;
  /* box-shadow: 0px 0px 23px 5px var(--bg3); */
}

.who-are-we-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.who-are-we-section {
  margin-bottom: 20px;
  max-width: 50vw;
}

.who-are-we-section h2 {
  font-size: 37px;
  margin-bottom: 10px;
  line-height: 50px;
}

@media (max-width: 1300px) {
  .who-are-we {
    padding: 50px 0px;
  }

  .who-are-we-image {
    margin: 0;
  }

  .who-are-we-image img {
    width: 500px;
  }

  .who-are-we-section h2 {
    font-size: 33px;
    line-height: 45px;
  }
}

@media (max-width: 1150px) {
  .who-are-we-image img {
    width: 450px;
  }

  .who-are-we-section h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

/* Responsive styling */
@media (max-width: 1050px) {
  .who-are-we {
    flex-direction: column;
    padding: 20px 0px;
  }

  .who-are-we-image {
    flex: 1;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .who-are-we-image img {
    width: 600px;
    margin: 20px 0 10px;
  }

  .who-are-we-section {
    flex: 1;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .who-are-we-section h2 {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
    padding: 0px;
  }
}

@media (max-width: 850px) {
  .who-are-we-content {
    align-items: flex-start;
  }

  .who-are-we-image img {
    width: 500px;
  }
}

@media (max-width: 500px) {
  .who-are-we-image img {
    width: 100%;
  }
}
