.modal {
  position: fixed;
  top: 50%;
  background-color: var(--bg1);
  color: var(--text);
  border: 2px solid rgb(103, 103, 103);
  z-index: 10;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  border-radius: 10px;
  padding: 15px 25px 20px;
  transition: 0.2s ease;
}

.modal h2 {
  font-family: var(--font2);
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 10px;
}

.modal i.cross-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 25px;
}

.modal label,
.modal input {
  display: block;
  margin: 8px 0 5px;
}

.modal .input-divider {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}

.modal input#name,
.modal input#email,
.modal textarea {
  width: 100%;
}
.modal input#name,
.modal input#email,
.modal textarea#message::placeholder {
  color: var(--inputText);
}
.modal textarea#message {
  color: var(--inputText);
}
.modal label {
  font-family: var(--font1);
}

.modal input,
.modal textarea {
  padding: 0 0 8px;
  outline: none;
  font-size: 16px;
  resize: vertical;
  border: none;
  border-bottom: 1px solid var(--text);
  background-color: var(--bg1);
}

.modal button {
  background-color: var(--text);
  border: none;
  outline: none;
  font-size: 15px;
  padding: 10px 25px;
  margin: 20px 0;
  font-weight: 500;
  font-family: var(--font2);
  color: var(--bg1);
  border-radius: 4px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #c98000;
}

.contact-source {
  display: flex;
  align-items: center;
  margin: 9px 0;
}

.contact-source i {
  font-size: 25px;
  font-weight: 500;
}

.contact-source p {
  margin-left: 30px;
  font-weight: 500;
  font-size: 17px;
}

@media (max-width: 510px) {
  .modal {
    width: calc(100% - 15px);
  }

  .modal .input-divider {
    grid-template-columns: repeat(1, 1fr);
  }
}
