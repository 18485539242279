.previous-client-container {
  background-color: var(--bg1);
  width: 100%;
  color: var(--text);
}

.previous-client-heading {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  color: var(--text);
  position: relative;
}

.previous-client-subheading-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 80%;
  line-height: 35px;
}

.previous-client-subheading {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 500;
  color: var(--text);
}

.carousel-container {
  display: flex;
  margin: 80px 0;
  flex-wrap: wrap;
  justify-content: center;
}

.company-description {
  font-size: 16px;
  font-weight: 600;
  color: var(--text);
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.company-card {
  width: 300px;
  height: 350px;
  color: var(--text);
  background: var(--companyCardBg);
  border: 1px solid var(--boarder);
  border-radius: 20px;
  text-align: center;
  position: relative;
  margin: 10px 0px;
}

.company-name .company-location {
  font-size: 15px;
  font-weight: 600;
  color: var(--text);
}

.previous-client-logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
  width: 100%;
}

.previous-client-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.previous-client-logo-subcontainer {
  display: flex;
  flex: 1 1 1;
  margin: 15px;
  justify-content: center;
  align-items: center;
  background-color: #fef9f8;
  border-radius: 20px;
  min-height: 150px;
  box-shadow: 5px 5px 12px 8px var(--shadow1);
  max-height: 200px;
}

.previous-client-logo-subcontainer img {
  margin: 0 15px;
  max-width: 70%;
  max-height: 100%;
  width: 20rem;
}

@media screen and (max-width: 800px) {
  .previous-client-subheading-container {
    width: 100%;
  }

  .previous-client-logos {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 440px) {
  .previous-client-logos {
    grid-template-columns: repeat(1, 1fr);
  }
}
