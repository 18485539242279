.code-quality-section {
  background-color: var(--bg2);

  .quality-cards-section {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 30px;
    column-gap: 20px;
    justify-items: center;

    .quality-card {
      background-color: #fef9f8;
      width: 320px;
      border-radius: 20px;
      box-shadow: 5px 5px 10px 8px var(--shadow3);
      cursor: pointer;

      &:hover {
        scale: 1.03;
      }

      .color-box {
        height: 15px;
        width: 100%;
        border-radius: 20px 20px 0 0;
      }

      .content {
        padding: 10px 20px 20px;

        h4 {
          font-size: 30px;
          height: 90px;
          color: black;
        }

        p {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .code-quality-section .quality-cards-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1100px) {
  .code-quality-section .quality-cards-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .code-quality-section .quality-cards-section {
    grid-template-columns: repeat(1, 1fr);
  }
}
