footer {
  background-color: var(--bg1);
  color: var(--text);
  padding: 40px 0;
}
footer .container {
  display: flex;
  justify-content: space-between;
}
footer .container .footer-left {
  width: 280px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
footer .container .footer-left p {
  font-size: 20px;
}
footer .container .footer-left .icons a {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 6px;
  margin-top: 20px;
  border: 2px solid var(--text);
  border-radius: 50px;
  width: 36px;
  height: 36px;
}
footer .container .footer-left .icons a:hover {
  scale: 1.2;
}
footer .container .footer-center {
  display: flex;
}
footer .container .footer-center ul {
  list-style-type: none;
}
footer .container .footer-center ul:nth-child(1) {
  margin-right: 80px;
}
footer .container .footer-center ul li a {
  text-decoration: none;
  color: var(--text);
  display: inline-block;
  padding: 8px 0;
}
footer .container .footer-center ul li a:hover {
  text-decoration: underline;
  scale: 1.03;
}
footer .container .footer-right p {
  padding: 8px 0;
}
footer .container .footer-right p a {
  text-decoration: none;
  color: var(--text);
  display: inline-block;
}
footer .container .footer-right .connect-options a {
  text-decoration: none;
}
footer .container .footer-right .connect-options a .email-option,
footer .container .footer-right .connect-options a .phone-option {
  background-color: #fef9f8;
  box-shadow: 5px 5px 10px 8px var(--shadow3);
  display: flex;
  align-items: center;
  width: 270px;
  margin-top: 20px;
  border-radius: 50px;
  cursor: pointer;
}
footer .container .footer-right .connect-options a .email-option:hover,
footer .container .footer-right .connect-options a .phone-option:hover {
  scale: 1.03;
}
footer
  .container
  .footer-right
  .connect-options
  a
  .email-option
  .image-container,
footer
  .container
  .footer-right
  .connect-options
  a
  .phone-option
  .image-container {
  border-radius: 50px;
  box-shadow: 4px 4px 34px 0px var(--shadow3);
}
footer
  .container
  .footer-right
  .connect-options
  a
  .email-option
  .image-container
  img,
footer
  .container
  .footer-right
  .connect-options
  a
  .phone-option
  .image-container
  img {
  margin: 6px;
  width: 52px;
}
footer .container .footer-right .connect-options a .email-option p,
footer .container .footer-right .connect-options a .phone-option p {
  flex: 1;
  text-align: center;
  font-family: var(--font2);
  font-size: 22px;
  font-weight: 500;
  color: black;
}
footer
  .container
  .footer-right
  .connect-options
  a
  .email-option
  .image-container
  img {
  width: 44px;
  margin: 10px;
}

@media (max-width: 1100px) {
  footer .container .footer-center ul:nth-child(1) {
    margin-right: 17px;
  }
  footer .container .footer-right .connect-options a .email-option,
  footer .container .footer-right .connect-options a .phone-option {
    width: 220px;
  }
  footer
    .container
    .footer-right
    .connect-options
    a
    .email-option
    .image-container
    img,
  footer
    .container
    .footer-right
    .connect-options
    a
    .phone-option
    .image-container
    img {
    margin: 6px;
    width: 42px;
  }
  footer .container .footer-right .connect-options a .email-option p,
  footer .container .footer-right .connect-options a .phone-option p {
    font-size: 20px;
  }
  footer
    .container
    .footer-right
    .connect-options
    a
    .email-option
    .image-container
    img {
    width: 37px;
    margin: 8px;
  }
}
@media (max-width: 800px) {
  footer .container {
    flex-direction: column;
    align-items: center;
  }
  footer .container .footer-center {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px 0;
  }
  footer .container .footer-center ul:nth-child(1) {
    margin-right: 0;
  }
}
