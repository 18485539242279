.full-content-container {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg1);
}

.full-content-container .content-container {
  background-color: #f1fff1;
  position: absolute;
  top: 30px;
  left: 20px;
  padding: 30px 100px;
  width: calc(100vw - 42px);
  height: calc(100vh - 60px);
  border-radius: 10px;
}

.full-content-container .back-btn {
  display: flex;
  flex-direction: row-reverse;
}

.full-content-container .back-btn p {
  padding: 5px 20px 10px;
  cursor: pointer;
}

.full-content-container h2 {
  font-size: 38px;
  line-height: 45px;
  font-weight: 700;
  height: auto;
  color: black;
  margin-bottom: 22px;
}

.full-content-container h3 {
  font-size: 26px;
  line-height: 35px;
  margin-top: 40px;
}

.full-content-container .card-tags p {
  font-size: 13px;
  padding: 4px 10px;
  margin: 0 8px 10px 0;
}

.full-content-container .card-content {
  overflow-y: scroll;
  height: calc(100vh - 210px);
  padding-bottom: 100px;
}

.card-tags + .card-content {
  height: calc(100vh - 270px);
}

.full-content-container .card-content p {
  font-size: 19px;
  margin: 12px 0 30px;
  line-height: 28px;
}

.full-content-container .card-content ul,
.full-content-container .card-content ol {
  padding: 0 30px;
}

.full-content-container .card-content ul li,
.full-content-container .card-content ol li {
  margin: 20px 0;
  font-size: 19px;
  line-height: 25px;
  margin-left: 30px;
}

@media (max-width: 1100px) {
  .full-content-container .content-container {
    padding: 30px 50px;
  }

  .card-tags + .card-content {
    height: calc(100vh - 310px);
  }
}

@media (max-width: 700px) {
  .full-content-container .content-container {
    top: 10px;
    left: 6px;
    padding: 30px;
    width: calc(100vw - 20px);
    height: calc(100vh - 16px);
  }

  .full-content-container .card-content {
    height: calc(100vh - 170px);
  }

  .full-content-container .card-content ul,
  .full-content-container .card-content ol {
    padding: 0 0 0 30px;
  }

  .full-content-container .card-content ul li,
  .full-content-container .card-content ol li {
    margin-left: 0;
  }

  .card-tags + .card-content {
    height: calc(100vh - 270px);
  }
}

@media (max-width: 600px) {
  .full-content-container .content-container {
    padding: 20px;
    width: calc(100vw - 20px);
    height: calc(100vh - 16px);
  }

  .full-content-container .card-content {
    height: calc(100vh - 160px);
  }

  .card-tags + .card-content {
    height: calc(100vh - 260px);
  }
}
