/* Desktop Navbar  */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  padding: 10px 60px;
  align-items: center;
  background-color: var(--bg1);
}

.navbar .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-title {
  font-size: 16px;
  margin-top: -8px;
}

.right-wrapper {
  align-items: center;
  display: flex;
}

.navbar-right ul li {
  display: inline-block;
}

.navbar-right ul li a,
.mobile-menu ul li a {
  color: var(--text);
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  font-family: var(--font2);
  font-size: 16px;
  font-weight: 500;
}

.navbar-right ul li a::after,
.mobile-menu ul li a::after {
  content: "";
  transition: 0.6s ease-in-out;
  width: 0;
  height: 4px;
  display: block;
  background-color: var(--yellow);
  margin: 5px auto 0;
  border-radius: 3px;
}

.navbar-right ul li a:hover::after,
.mobile-menu ul li a:hover::after {
  width: 100%;
}

.navbar .switch i {
  font-size: 25px;
  margin-left: 40px;
  width: 35px;
  cursor: pointer;
  color: var(--yellow);
}

.navbar .menu {
  display: none;
  font-size: 25px;
  cursor: pointer;
  color: var(--text);
  margin-left: 15px;
}

/* Mobile Menu */
.mobile-menu {
  position: fixed;
  top: -100vh;
  left: 0;
  background-color: var(--bg1);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-menu.active {
  top: 0;
}

.mobile-menu ul {
  list-style-type: none;
}

.mobile-menu ul li a {
  padding: 10px 0;
  text-align: center;
}

.mobile-menu .cross-icon {
  color: var(--yellow);
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 25px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .navbar {
    padding: 10px;
  }

  .navbar-right ul li a,
  .mobile-menu ul li a {
    padding: 5px 5px;
  }
}

@media (max-width: 850px) {
  .navbar {
    padding: 0px 20px;
  }

  .navbar .logo-title {
    display: none;
  }

  .navbar .navbar-right {
    display: none;
  }

  .navbar .menu {
    display: flex;
  }
}
