.our-work-container {
  background-color: var(--bg1);
}

.card-container {
  color: var(--text);
  display: flex;
  align-items: center;
}

.card-container.reverse {
  flex-direction: row-reverse;
}

.card-container img {
  width: 600px;
  height: 600px;
  object-fit: cover;
}

.card-container h2 {
  line-height: 60px;
  font-size: 43px;
}

.card-container:hover h2 {
  scale: 1.03;
  transition: 0.2s ease;
}

.card-container h2.right {
  margin: 0 0 0 50px;
}

@media (max-width: 1450px) {
  .card-container img {
    width: 500px;
    height: 500px;
  }
}

@media (max-width: 1100px) {
  .card-container img {
    width: 400px;
    height: 400px;
  }

  .card-container h2 {
    line-height: 50px;
    font-size: 32px;
  }

  .card-container h2.right {
    margin: 0 0 0 20px;
  }
}

@media (max-width: 800px) {
  .card-container {
    display: block;
    margin: 20px 0 50px;
  }

  .card-container img {
    width: 400px;
    height: 300px;
  }

  .card-container img.right,
  .card-container img.left {
    border-radius: 20px 20px 0 0;
  }

  .card-container h2.right,
  .card-container h2.left {
    margin: 0;
  }
}

@media (max-width: 420px) {
  .card-container img {
    width: 100%;
    height: auto;
  }
}
