.connect-us-container {
  background-color: var(--bg2);
  padding: 0 0 50px 0;

  h2 {
    text-align: center;
    font-family: var(--font2);
    font-size: 60px;
  }

  .connect-options {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;

    a {
      text-decoration: none;

      .phone-option,
      .email-option {
        background-color: #fef9f8;
        box-shadow: 5px 5px 10px 8px var(--shadow3);
        display: flex;
        align-items: center;
        width: 380px;
        border-radius: 50px;
        cursor: pointer;

        &:hover {
          scale: 1.03;
        }

        .image-container {
          border-radius: 50px;
          box-shadow: 4px 4px 34px 0px var(--shadow3);
          img {
            margin: 10px;
            width: 80px;
            height: 80px;
          }
        }

        p {
          flex: 1;
          text-align: center;
          font-family: var(--font2);
          font-size: 32px;
          font-weight: 500;
          color: black;
        }
      }

      .email-option .image-container img {
        width: 65px;
        margin: 17px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .connect-us-container .connect-options a .email-option,
  .connect-us-container .connect-options a .phone-option {
    width: 360px;
  }
}

@media (max-width: 800px) {
  .connect-us-container .connect-options {
    flex-direction: column;
    align-items: center;

    a .email-option {
      margin-top: 30px;
    }
  }
}

@media (max-width: 530px) {
  .connect-us-container {
    h2 {
      font-size: 44px;
    }
    .connect-options a .email-option p,
    .connect-options a .phone-option p {
      font-size: 28px;
    }
  }
}
