.how-we-work-section {
  background-color: var(--bg1);
}
.how-we-work-section h1 {
  margin-bottom: 60px;
}
.how-we-work-section h2 {
  text-align: center;
}
.how-we-work-section h4 {
  text-align: center;
  color: var(--text);
  font-size: 20px;
  margin-top: 20px;
  font-weight: 400;
}
.how-we-work-section .work-cards .work-card {
  background-color: #20232a;
  border: 2px solid rgb(106, 106, 106);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 45px auto 0;
}
.how-we-work-section .work-cards .work-card:nth-child(1),
.how-we-work-section .work-cards .work-card:nth-child(4) {
  width: 80%;
}
.how-we-work-section .work-cards .work-card:nth-child(1) ol,
.how-we-work-section .work-cards .work-card:nth-child(4) ol {
  width: 300px;
}
.how-we-work-section .work-cards .work-card img {
  width: 90px;
  height: 90px;
}
.how-we-work-section .work-cards .work-card h3 {
  color: var(--text);
}
.how-we-work-section .work-cards .work-card h3,
.how-we-work-section .work-cards .work-card h3 span {
  font-size: 22px;
  line-height: 35px;
  width: 300px;
  text-align: center;
  font-weight: 600;
  font-family: var(--font2);
}
.how-we-work-section .work-cards .work-card h3.expanded-red,
.how-we-work-section .work-cards .work-card h3.green,
.how-we-work-section .work-cards .work-card h3.blue {
  font-size: 22px;
  font-family: var(--font2);
  font-weight: 600;
}
.how-we-work-section .work-cards .work-card ol {
  width: 400px;
  list-style-type: none;
}
.how-we-work-section .work-cards .work-card ol li {
  color: var(--text);
  font-size: 17px;
  line-height: 25px;
}

@media (max-width: 800px) {
  .how-we-work-section .work-cards .work-card {
    flex-direction: column;
    padding: 25px 20px;
    margin-top: 20px;
  }
  .how-we-work-section .work-cards .work-card:nth-child(1) ol {
    width: 100%;
  }
  .how-we-work-section .work-cards .work-card:nth-child(4) {
    margin-top: 20px;
  }
  .how-we-work-section .work-cards .work-card:nth-child(4) ol {
    width: 100%;
  }
  .how-we-work-section .work-cards .work-card img {
    width: 120px;
    height: 120px;
  }
  .how-we-work-section .work-cards .work-card h3 {
    margin: 0px 0 10px;
    width: 100%;
  }
  .how-we-work-section .work-cards .work-card ol {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .how-we-work-section .work-cards .work-card:nth-child(4),
  .how-we-work-section .work-cards .work-card:nth-child(1) {
    width: 100%;
  }
} /*# sourceMappingURL=HowWeWork.css.map */
