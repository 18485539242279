.code-quality-section {
  background-color: var(--bg2);
}
.code-quality-section .quality-cards-section {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 30px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  justify-items: center;
}
.code-quality-section .quality-cards-section .quality-card {
  background-color: #fef9f8;
  width: 320px;
  border-radius: 20px;
  box-shadow: 5px 5px 10px 8px var(--shadow3);
  cursor: pointer;
}
.code-quality-section .quality-cards-section .quality-card:hover {
  scale: 1.03;
}
.code-quality-section .quality-cards-section .quality-card .color-box {
  height: 15px;
  width: 100%;
  border-radius: 20px 20px 0 0;
}
.code-quality-section .quality-cards-section .quality-card .content {
  padding: 10px 20px 20px;
}
.code-quality-section .quality-cards-section .quality-card .content h4 {
  font-size: 30px;
  height: 90px;
  color: black;
}
.code-quality-section .quality-cards-section .quality-card .content p {
  font-size: 18px;
  line-height: 27px;
}

@media (max-width: 1440px) {
  .code-quality-section .quality-cards-section {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1100px) {
  .code-quality-section .quality-cards-section {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 800px) {
  .code-quality-section .quality-cards-section {
    grid-template-columns: repeat(1, 1fr);
  }
} /*# sourceMappingURL=HowDoWeEnsure.css.map */
