.how-we-work-section {
  background-color: var(--bg1);

  h1 {
    margin-bottom: 60px;
  }

  h2 {
    text-align: center;
  }

  h4 {
    text-align: center;
    color: var(--text);
    font-size: 20px;
    margin-top: 20px;
    font-weight: 400;
  }

  .work-cards {
    .work-card {
      background-color: #20232a;
      border: 2px solid rgb(106, 106, 106);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-radius: 20px;
      margin: 45px auto 0;

      &:nth-child(1),
      &:nth-child(4) {
        width: 80%;

        ol {
          width: 300px;
        }
      }

      img {
        width: 90px;
        height: 90px;
      }

      h3 {
        color: var(--text);
      }

      h3,
      h3 span {
        font-size: 22px;
        line-height: 35px;
        width: 300px;
        text-align: center;
        font-weight: 600;
        font-family: var(--font2);
      }

      h3.expanded-red,
      h3.green,
      h3.blue {
        font-size: 22px;
        font-family: var(--font2);
        font-weight: 600;
      }

      ol {
        width: 400px;
        list-style-type: none;

        li {
          color: var(--text);
          font-size: 17px;
          line-height: 25px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .how-we-work-section {
    .work-cards {
      .work-card {
        flex-direction: column;
        padding: 25px 20px;
        margin-top: 20px;

        &:nth-child(1) ol {
          width: 100%;
        }

        &:nth-child(4) {
          margin-top: 20px;

          ol {
            width: 100%;
          }
        }

        img {
          width: 120px;
          height: 120px;
        }

        h3 {
          margin: 0px 0 10px;
          width: 100%;
        }

        ol {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .how-we-work-section {
    .work-cards {
      .work-card:nth-child(4),
      .work-card:nth-child(1) {
        width: 100%;
      }
    }
  }
}
