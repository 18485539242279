.our-work-container {
  background-color: var(--bg1);
}

.our-work-container .container .cards {
  display: flex;
  margin-top: 30px;
  overflow-x: scroll;
}

.our-work-container .container .cards .card-container {
  background-color: #fef9f8;
  padding: 15px 20px 25px;
  border-radius: 20px;
  color: black;
  display: inline-block;
  flex: 0 0 365px;
  margin: 30px 15px;
  height: 420px;
  position: relative;
  text-align: left;
  box-shadow: 5px 5px 12px 8px var(--shadow2);
}

.our-work-container .container .cards .card-container:hover {
  scale: 1.02;
}

#our-work-card-heading {
  color: black;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  font-family: var(--font2);
  height: 110px;
}

.our-work-container .work-card-content {
  height: 190px;
}

.card-tags {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
}

.card-tags p {
  background-color: #f6b543;
  color: rgb(39, 39, 39);
  margin: 5px 8px 0 0;
  padding: 0px 9px;
  font-size: 12px;
  line-height: 23px;
  border-radius: 3px;
  font-weight: 600;
  font-family: var(--font2);
}

.read-more-button {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.read-more-button button {
  border-radius: 8px;
  border: none;
  border: 2px solid black;
  padding: 3px 12px 8px;
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
  background-color: black;
  color: white;
}

.read-more-button button:hover {
  color: black;
  background-color: white;
}
