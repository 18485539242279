.contact-icon {
  position: fixed;
  bottom: 40px;
  right: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.contact-icon:hover {
  scale: 1.1;
}

.contact-icon img {
  width: 60px;
  height: 60px;
  margin: 12px 14px;
}

@media (max-width: 600px) {
  .contact-icon {
    bottom: 20px;
    right: 20px;
  }

  .contact-icon img {
    width: 50px;
    height: 50px;
  }
}

.contact-type-form {
  all: unset;
  font-family: Helvetica, Arial, sans-serif;
  display: inline-block;
  width: 82px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  padding: 2px;
  font-weight: 400;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  margin: 0;
  text-decoration: none;
  background-color: transparent;
}
