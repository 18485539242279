@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Niramit:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --font1: "Niramit", sans-serif;
  --font2: "Poppins", sans-serif;
  --blue: #3892ff;
  --red: #f44250;
  --green: #6ad868;
  --aqua: #3cefe9;
  --softRed: #e66465;
  --blackBean: #222329;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font1);
  transition: 0.5s ease;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

.app-container .switch {
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-container .switch .icon {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
  z-index: 9999999999;
}

.modal-open {
  filter: blur(10px);
  pointer-events: none;
}

.dark {
  --text: rgb(255, 255, 255);
  --text2: #000000;
  --cardbg: rgb(255, 255, 255);
  --bg1: rgba(0, 0, 0, 1);
  --bg2: rgba(0, 0, 0, 0.93);
  --bg3: rgba(0, 0, 0, 0.9);
  --bg4: rgba(43, 43, 43, 0.8);
  --shadow1: black;
  --shadow2: rgb(32, 32, 32);
  --shadow3: black;
  --yellow: #ffb532;
  --border: #ddd;
  --boader2: var(--red);
  --companyCardBg: linear-gradient(85deg, #434343, #262626);
  --workCardImg: url("/public/Images/whatsappBg.jpeg");
  --boxShadowColor: rgba(255, 255, 255, 0.327);
  --boxShadowColor2: rgba(245, 245, 245, 0.2);
  --cardBackground: var(--blackBean);
}
.light {
  --text: #000000;
  --text2: #000000;
  --cardbg: rgba(0, 0, 0, 0.9);
  --inputText: rgb(255, 255, 255);
  --bg1: rgba(255, 255, 255, 1);
  --bg2: rgba(243, 243, 243, 0.93);
  --bg3: rgba(225, 225, 225, 0.9);
  --shadow1: rgb(231, 231, 231, 0.6);
  --shadow2: rgba(227, 227, 227, 0.73);
  --shadow3: rgba(225, 225, 225, 0.9);
  --yellow: #e7970e;
  --border: #ddd;
  --boader2: var(--green);
  --companyCardBg: linear-gradient(85deg, #e7e7e7, #cecece);
  --boxShadowColor: rgba(0, 0, 0, 0.327);
  --workCardImg: url("/public/Images/whatsapp.jpg");
  --boxShadowColor2: rgb(128, 128, 128, 0.5);
  --cardBackground: white;
}

/* Logo Title */
.logo-title {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--text);
  text-align: center;
  font-family: var(--font2);
}

/* All Heading Styles */
h1 {
  font-size: 96px;
  font-weight: 700;
  color: var(--text);
  font-family: var(--font2);
  line-height: 110px;
}

h1::after {
  content: "";
  width: 280px;
  height: 15px;
  display: block;
  background-color: var(--yellow);
}

h2 {
  font-size: 40px;
  line-height: 60px;
  color: var(--text);
}

h2.after::after {
  content: "";
  width: 90px;
  height: 8px;
  display: block;
  background-color: var(--yellow);
  margin-top: -5px;
}

.red {
  color: var(--red);
}

.blue {
  color: var(--blue);
}

.green {
  color: var(--green);
}

.aqua {
  color: var(--aqua);
}

.yellow {
  color: var(--yellow);
}

.italic {
  font-style: italic;
}

/* Theme */
.dark .logo img {
  filter: invert(0);
  width: 65px;
  height: 65px;
}

.light .logo img {
  filter: invert(1);
  width: 65px;
  height: 65px;
}

.dark .footer img {
  filter: invert(0);
}

.light .footer img {
  filter: invert(1);
}

.dark .navbar .menu-div .menu {
  filter: invert(0);
}

.light .navbar .menu-div .menu {
  filter: invert(1);
}

.container {
  width: 1350px;
  margin: 0 auto;
  padding: 70px 0;
  /* border: 1px solid red; */
}

/* Responsive Styling */
@media (max-width: 1450px) {
  .container {
    width: 1024px;
  }

  h1 {
    font-size: 80px;
  }

  h1::after {
    width: 265px;
    height: 13px;
  }
}

@media (max-width: 1100px) {
  .container {
    width: 764px;
  }

  h1 {
    font-size: 70px;
  }

  h1::after {
    width: 220px;
    height: 11px;
  }
}

@media (max-width: 800px) {
  .container {
    width: 524px;
  }

  h1 {
    font-size: 55px;
    line-height: 75px;
  }

  h1::after {
    width: 190px;
    height: 11px;
  }

  h2 {
    font-size: 35px;
  }
}

@media (max-width: 530px) {
  .container {
    width: calc(100% - 15px);
    padding: 30px 5px;
  }

  h1 {
    font-size: 44px;
    line-height: 60px;
  }

  h1::after {
    width: 150px;
    height: 8px;
  }
}
