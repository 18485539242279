.previous-work-examples-container {
  background-color: var(--bg2);
}

.previous-work-examples-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.previous-work-examples-list {
  padding: 10px;
}

.previous-work-examples-item {
  width: 360px;
  height: 490px;
  background: var(--workCardImg);
  object-fit: cover;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 1;
  border: 1px solid rgba(0, 0, 0, 0.149);
  margin: 10px 0;
}

.previous-work-examples-tag {
  width: 100%;
  color: var(--text);
  font-size: 14px;
  font-weight: bold;
  display: flex;
}

.tags {
  color: var(--text);
  font-weight: 900;
  margin-right: 15px;
  background-color: var(--bg1);
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: center;
}

.previous-work-examples-title {
  font-size: 30px;
  font-weight: 900;
  color: var(--text);
}

.previous-work-examples-description {
  font-size: 17px;
  font-weight: 400;
  color: var(--text);
  line-height: 27px;
  height: 15vw;
}

.previous-work-examples-image {
  width: 100%;
  height: auto;
  margin-top: 10px;
  transition: 1;
  object-fit: cover;
}
.read-more {
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}

.expanded {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--text);
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  transition: 1;
  padding: 100px 0px;
}

.expanded .expanded-image {
  width: 0%;
  height: 0%;
  object-fit: cover;
}

.expanded .close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: var(--text);
  font-weight: 400;
}

.expanded .expanded-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 200px;
  text-align: start;
  color: var(--text);
  margin-top: 80px;
  height: 100%;
}

.expanded-previous-work-examples-tag {
  position: fixed;
  top: 3%;
  left: 3%;
  width: 100%;
  color: var(--text);
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
}

.expanded .expanded-content .previous-work-examples-title {
  margin-top: 150px;
  margin-bottom: 30px;
  font-size: 70px;
}

.expanded .read-more-description {
  font-size: 22px;
  cursor: pointer;
  font-weight: 400;
}

.expanded .expanded-content .previous-work-examples-description {
  font-size: 22px;
  font-weight: 400;
}

.points {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 400;
  text-align: start;
}

.points li {
  margin-bottom: 15px;
}

.read-more-btn {
  margin-top: 10px;
  text-align: end;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  font-weight: 900;
  color: var(--text);
}

.expanded-red {
  color: var(--red);
  font-weight: 600;
}

.expanded-green {
  color: var(--green);
  font-weight: 600;
}

.expanded-yellow {
  color: var(--yellow);
  font-weight: 600;
}

.expanded-blue {
  color: var(--blue);
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .previous-work-examples-description {
    height: auto;
  }

  .previous-work-examples-title {
    font-size: 24px;
  }

  .expanded {
    padding: 50px 0px;
  }

  .expanded .expanded-content {
    padding: 0px 50px;
  }

  .expanded .expanded-content .previous-work-examples-title {
    margin-top: 100px;
    margin-bottom: 30px;
    font-size: 50px;
  }

  .expanded .expanded-content .previous-work-examples-description {
    font-size: 20px;
    font-weight: 400;
  }

  .points {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    text-align: start;
  }

  .points li {
    margin-bottom: 15px;
  }

  .read-more-btn {
    margin-top: 10px;
    text-align: end;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    font-weight: 900;
    color: var(--text);
  }

  .expanded .close-button {
    position: fixed;
    top: 20px;
    right: 80px;
    cursor: pointer;
    color: var(--text);
    font-weight: 400;
  }
}

@media screen and (max-width: 480px) {
  .previous-work-examples-container {
    padding: 4vw;
  }

  .previous-work-examples-list {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .previous-work-examples-item {
    width: 100%;
  }

  .previous-work-examples-title {
    font-size: 35px;
    font-weight: 900;
  }

  .previous-work-examples-description {
    font-size: 18px;
    line-height: 25px;
  }

  .expanded {
    padding: 50px 0px;
  }

  .expanded .expanded-content {
    padding: 0px 50px;
  }

  .expanded .expanded-content .previous-work-examples-title {
    margin-top: 500px;
    margin-bottom: 30px;
    font-size: 40px;
  }

  .expanded .expanded-content .previous-work-examples-description {
    font-size: 18px;
    font-weight: 400;
  }

  .points {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
    text-align: start;
  }

  .points li {
    margin-bottom: 15px;
  }

  .read-more-btn {
    margin-top: 10px;
    text-align: end;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    font-weight: 900;
    color: var(--text);
  }

  .expanded .close-button {
    position: fixed;
    top: 20px;
    right: 18px;
    cursor: pointer;
    color: var(--text);
    font-weight: 800;
  }
}
